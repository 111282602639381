import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import {Config} from "./config/config"
import sessionService from "./session/session.service"
import PushNotificationService from "./push-notification/push-notification.service";

class ApiClientService {

    async configureClient() {
        const session = await sessionService.validateSession()

        axios.defaults.baseURL = Config.apiRoot
        axios.defaults.timeout = Config.timeout
        axios.defaults.headers.common = { Authorization: `token ${session.token}` }

        await PushNotificationService.setup()

        axios.interceptors.request.use(this.onRequest)
        axios.interceptors.response.use(this.onResponse, this.onResponseError)
    }

    onRequest(request: AxiosRequestConfig) {
        console.log("REQUEST: ", request)
        return request
    }

    onResponse(response: AxiosResponse) {
        console.log("RESPONSE: ", response)

        return response
    }

    onResponseError(error: AxiosError) {

        if (error?.response?.status === 429)
            sessionService.validateSession()

        return Promise.reject(error)
    }

}

export default new ApiClientService()