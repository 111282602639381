import React, { useEffect } from 'react';
import style from './billing-details.module.scss';
import {Subscription} from '../../../../../../service/billing/model/Subscription';
import InputButton from '../../../../../../theme/component/input-button';
import { ButtonColor } from '../../../../../../theme/component/input-button/models/button-color.model';
import SubscriptionCancelBox from './subscription-cancel-box.component';
import { useNavigate } from 'react-router-dom';
import ISettings from "../../../../../../service/settings/model/settings.model"; // Import useNavigate
import { useTranslation } from 'react-i18next';

type BillingDetailsProps = {
    settings: ISettings;
};

export default function BillingDetails({settings}: BillingDetailsProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [subscription, setSubscription] = React.useState<Subscription>(null);
    const [openSubscriptionCancelBox, setOpenSubscriptionCancelBox] = React.useState<boolean>(false);

    const navigateToBillings = () => {
        navigate('/premium');
    };

    useEffect(() => {
        setSubscription(settings?.subscription);
    }, [settings]);

    if (subscription === undefined) {
        return (
            <>
                <div className={style.iconWithText}>
                    <div className={style.icon} />
                    <div>
                        <div>{t('billing.title')}</div>
                        <div className={style.info}>{t('billing.manage_methods')}</div>
                    </div>
                </div>
                <div>{t('billing.free_subscription')}</div>
            </>
        );
    }

    if (subscription === null) {
        return (
            <>
                <div className={style.iconWithText}>
                    <div className={style.icon} />
                    <div>
                        <div>{t('billing.title')}</div>
                        <div className={style.info}>{t('billing.join_now')}</div>
                    </div>
                </div>
                <div>{t('billing.free_subscription')}</div>
                <InputButton className={style.button_Right} color={ButtonColor.Primary} label={t('billing.subscribe_now')} onClick={navigateToBillings} />
            </>
        );
    }

    return (
        <>
            <SubscriptionCancelBox open={openSubscriptionCancelBox} onClose={() => setOpenSubscriptionCancelBox(false)} />
            <div className={style.iconWithText}>
                <div className={style.icon} />
                <div>
                    <div>{t('billing.title')}</div>
                    <div className={style.info}>{t('billing.manage_methods')}</div>
                </div>
            </div>
            <div>
                <div className={style.subscriptionContainer}>
                    <div>{subscription.name}</div>
                    <div className={style.rate}>Rate: {subscription.rate.replace('.', ',')}€</div>
                    <div className={style.nextBilling}>{subscription.active ? `${t('billing.next_billing')} ${formatDate(new Date(subscription.paidUntil))}` : `${t('billing.paid_until')} ${formatDate(new Date(subscription.paidUntil))}`}</div>
                    <div className={style.rate}>{subscription.active ? t('billing.status_active') : t('billing.status_cancelled')}</div>
                    {subscription.active ? <InputButton color={ButtonColor.Failure} label={t('billing.cancel_subscription')} onClick={() => setOpenSubscriptionCancelBox(true)} /> : <></>}
                </div>
            </div>
        </>
    );
}

//format date to dd.MM.yyyy
function formatDate(date: Date) {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
}
