import React, {useEffect, useState} from 'react';
import { NotificationService } from '../../../service/notification/notification.service';
import { NotificationType } from '../../../service/notification/model/notification-type.enum';
import { ConfirmationDialog } from '../../../theme/component/confirmation-dialog';
import ProfileService from '../../../service/profile/profile.service';
import { useTranslation } from 'react-i18next';

type MenuProps = {
    username: string;
    onBlock: (username: string) => void;
    openDialog: boolean;
    setDialogOpen: (open: boolean) => void;
};

export default function BlockDialog({ username, onBlock, openDialog, setDialogOpen }: MenuProps) {
    const { t } = useTranslation();

    return (
            <ConfirmationDialog
                payload={undefined}
                onConfirm={blockUsername}
                title={t('menu.block_title')}
                dialog={t('menu.block_dialog', { username })} // username: username
                actionButtonText={t('menu.block')}
                open={openDialog}
                onClose={() => setDialogOpen(false)}
            />
    );

    async function blockUsername() {
        try {
            setDialogOpen(false);
            NotificationService.displayNotification({
                message: t('menu.block_success'),
                type: NotificationType.success
            });
            onBlock(username);
            await ProfileService.blockProfile(username);
        } catch (error) {
            NotificationService.displayNotification({
                message: t('menu.block_error'),
                type: NotificationType.failure
            });
        }
    }

}
