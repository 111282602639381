import { Capacitor } from '@capacitor/core';


export const Config = {
    environment: process.env.REACT_APP_MODE,
    isProduction: process.env.REACT_APP_MODE === 'PRODUCTION',
    isDevelopment: process.env.REACT_APP_MODE === 'DEVELOPMENT',

    host: process.env.REACT_APP_HOST || (Capacitor.isNativePlatform() ? 'https://influspace.com' :
        window.location.protocol + '//' + window.location.hostname),
    apiRoot: process.env.REACT_APP_API_ROOT || (Capacitor.isNativePlatform() ? 'https://influspace.com/api' :
        window.location.protocol + '//' + window.location.hostname + '/api'),
    wsRoot: process.env.REACT_APP_WS_ROOT || (Capacitor.isNativePlatform() ? 'wss://influspace.com/api/chat/subscribe' :
        'wss://' + window.location.hostname + '/api/chat/subscribe'),


    timeout: 10000
}