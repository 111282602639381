import axios from "axios";
import {ThirdPartyAuthorizationResponse} from "./model/third-party-authorization.response";
import {Capacitor} from "@capacitor/core";

export class ThirdPartyAuthorizationService {

    private static rootUrl = '/account'
    private static client = axios

    static async getAuthorizeEndpoint(provider: string, referredBy?: string): Promise<string> {
        try {
            const response = await this.client.get(`${this.rootUrl}/authorize/oauth-endpoint`, {
                params: {
                    provider,
                    referredBy,
                    platform: Capacitor.getPlatform()
                }
            })

            return response.data
        } catch(error) {
            console.log(error)
            return null
        }

    }


    static async authorize(provider: string, code: string, state: string, signal: AbortSignal): Promise<ThirdPartyAuthorizationResponse> {
        try {
            const response = await this.client.post(
                `${this.rootUrl}/authorize`,
                { code, state }, // Code und State im Body senden
                { signal, params: { provider, platform: Capacitor.getPlatform } }
            );

            const thirdPartyAuthorizationResponse = response?.data as ThirdPartyAuthorizationResponse;
            console.log(thirdPartyAuthorizationResponse);

            return thirdPartyAuthorizationResponse;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

}