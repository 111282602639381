import React, {useEffect, useState} from "react";
import style from './index.module.scss';
import SessionService from "../../service/session/session.service";
import {useNavigate} from "react-router-dom";
import Panel from "../../theme/component/panel/panel.component";
import {BillingService} from "../../service/billing/billing.service";
import {DesktopPaymentPicker} from "../../theme/component/payment-picker/desktop";
import {useTranslation} from 'react-i18next';

import {BrandAccessBenefitsSection, BrandMonthlyPlan, BrandYearlyPlan} from "../../theme/component/payment-plans";
import {Capacitor} from "@capacitor/core";
import {InAppPurchaseService} from "../../service/iap/InAppPurchaseService";
import {ProductEnum} from "../../service/iap/model/ProductEnum";

type PayPalPlan = {
    planId: string,
    name: string
    type: ProductEnum
}

const isProduction = window.location.hostname === 'influspace.com'

const brandAccessMonthly = {
    // regular plan P-0NV86914HN9171738MY4OZXA
    // black friday plan P-6GB050818P498692VM5EQ6HA
    planId: isProduction ? 'P-0NV86914HN9171738MY4OZXA' : 'P-63340961HC971183YMWYC55A',
    name: 'Monthly Payment Plan for Brand Access',
    type: ProductEnum.BRAND_YEARLY
} as PayPalPlan

const brandAccessYearly = {
    // regular plan P-9NG5521757479102WMY4O2BQ
    // black friday plan P-5CR96231TA388092HM5EQ5NQ
    planId: isProduction ?  'P-9NG5521757479102WMY4O2BQ' : 'P-1AY65338S2323582HMWYC5AQ',
    name: 'Yearly Payment Plan for Brand Access',
    type: ProductEnum.BRAND_YEARLY

} as PayPalPlan


const plans = [brandAccessYearly, brandAccessMonthly]

export default function BrandAccess() {

    const [planId, setPlanId] = useState<string>(undefined)
    const [planName, setPlanName] = useState<string>(undefined)

    const navigate = useNavigate()
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setPlanName(plans.find(plan => plan.planId === planId)?.name)
    }, [planId]);


    useEffect(() => {
        const session = SessionService.getSessionDetails()
        if (session.premium) {
            navigate('/explore')
        }
    }, [navigate]);

    async function createSubscription(data: any, actions: any)  {
        return actions.subscription.create({
            plan_id: planId,
        });
    }

    async function onApprove(data: any, actions: any) {
        BillingService.subscribe(data.subscriptionID, 'paypal').then(() => window.location.reload())
    }

    async function onBuy(planId: string) {
        if (Capacitor.isNativePlatform()) {
            const plan = plans.find(plan => plan.planId === planId)
            await InAppPurchaseService.purchaseSubscription(plan.type)
        } else {
            setPlanId(planId)
        }
    }

    if (planId) {
        return <DesktopPaymentPicker name={planName} createSubscription={createSubscription} onApprove={onApprove} />
    }

    return (
        <section>
            <Panel className={style.panel}>
                <h1 className={style.header}>{t('brand_access_title')}</h1>
                <h3 className={style.topText}>{t('brand_access_description')}</h3>
                <BrandAccessBenefitsSection></BrandAccessBenefitsSection>
                <div className={style.plansGrid}>
                    <BrandMonthlyPlan planId={brandAccessMonthly.planId} onBuy={onBuy} />
                    <BrandYearlyPlan planId={brandAccessYearly.planId} onBuy={onBuy} />
                </div>
            </Panel>
        </section>
    )


}