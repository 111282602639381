import React, { useState } from "react";
import style from './index.module.scss'
import { useNavigate } from "react-router-dom";
import {IOffer} from "../../../service/marketplace/model/offer.model";
import marketplaceService from "../../../service/marketplace/marketplace.service";
import {OfferHeader} from "./component/header";
import {Bookmark} from "../../../theme/component/bookmark";
import {CampaignMenu} from "../CampaignMenu";

type OfferTileProps = {
    offer: IOffer
    removeFromTimeline: (username: string) => void
}

export function CampaignOffer({ offer }: OfferTileProps) {
    const navigate = useNavigate()

    const [bookmarked, setBookmarked] = useState<boolean>(offer?.bookmarked);
    const [openContextMenu, setOpenContextMenu] = useState<boolean>(false)

    if (offer === undefined) {
        return <></>;
    }

    function Description({ description }: { description: string }) {
        return <div className={style.description_short}>
            {description}
        </div>
    }

    function CampaignOptions() {
        if (offer.owned) {
            return <></>
        }

        return <>
            <div className={style.context_menu}>
                <span onClick={bookmarkOffer} className={style.bookmark}><Bookmark enabled={bookmarked} /></span>
                <span onClick={() => setOpenContextMenu(true)} className={style.menu} id='menu'>•••</span>
            </div>
        </>
    }

    async function bookmarkOffer() {
        try {
            await marketplaceService.bookmarkOffer(offer)
            setBookmarked(!bookmarked)
        } catch (error) {
            //TODO: Meldung wenn like failed
        }
    }

    function onClickHandler(event: any) {
        if (event.target.id !== 'bookmark' && event.target.id !== 'apply' && event.target.id !== 'menu') {
            navigate(offer.premiumOnlyUntil ? '/premium' : `/marketplace/offer/${offer.ref}`)
        }
    }

    return (
        <>
            <span className={style.panel} onClick={(event => onClickHandler(event))}>
                <OfferHeader offer={offer} />
                <CampaignOptions />
            </span>
            <CampaignMenu campaignId={offer?.ref}
                          username={offer?.author?.username}
                          closeDialog={() => setOpenContextMenu(false)}
                          openDialog={openContextMenu}
                          removeFromTimeline={username => console.log('remove from timeline', username)} />
        </>
    );
}



