import React from "react";
import ProfileView from "../../../../../../../../service/profile/model/profile-view.model";
import style from "./index.module.scss"
import {useNavigate} from "react-router-dom";
import { Config } from "../../../../../../../../service/config/config";
import defaultAvatar from "../../../../../../../../theme/resource/image/default/avatar_default.png";
import InputButton from "../../../../../../../../theme/component/input-button";

type BlockedTileProps = {
    profile: ProfileView
    onUnblock: (profile: ProfileView) => void
}

function Avatar({ username, avatarUrl }: { username: string, avatarUrl: string}) {
    const navigate = useNavigate()

    const avatar = avatarUrl ? Config.host + avatarUrl : defaultAvatar

    return (
        <span className={style.avatar_container} onClick={() => navigate(`/profile/${username}`)}>
            <img src={avatar} className={style.avatar} />
        </span>
    )
}
export function BlockedTile({ profile, onUnblock} : BlockedTileProps) {
    return <div className={style.container}>
        <Avatar username={profile.username} avatarUrl={profile.avatar}/>
        <span className={style.displayname}>{profile.displayname}</span>
        <InputButton label={'Unblock'} className={style.unblock_button} onClick={() => onUnblock(profile)}/>
    </div>
}