import React, { useState } from 'react'
import ProfileView from '../../../../service/profile/model/profile-view.model'

import Banner from './component/banner/banner.component'
import Meta from './component/meta/meta.component'

type ProfileHeaderProps = {
    profile: ProfileView,
    editModeChanged: (mode: boolean) => void
    openContextMenu: () => void
}

export default function ProfileHeader({ profile, editModeChanged, openContextMenu }: ProfileHeaderProps) {
    const [editMode, setEditMode] = useState<boolean>(false)

    function onEditModeChange(editingMode: boolean) {
        setEditMode(editingMode)
        editModeChanged(editingMode)
    }

    return (
        <>
            <Banner profile={profile} editingMode={editMode} openContextMenu={() => { openContextMenu(); console.log('hier  ')}} />
            <Meta profile={profile} editModeChanged={onEditModeChange} />
        </>
    )
}