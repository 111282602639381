import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import App from './components/app/app.component'
import apiClientService from './service/api-client.service'
import Loading from './theme/layout/loading/loading.component'
import {PayPalScriptProvider} from '@paypal/react-paypal-js'
import './utility/localization.utility'
import {BrowserRouter} from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const paypalClientId = window.location.hostname === 'influspace.com' ?
    "AYvD2qI4t6AeNuvTUkwzp5ka4tYEkTHa3EpCicSLnxb2n2qSAPhten0lc8SjaSMTGkUO0zTxSlEJrRpz" :
    "Adeui3IA3qdVd6yAxj9vTF6-rXiyHEkaAdqMyveNAZt3I2ufqqc8TA4gRzfrbtI3PP_ynmQzIBY6qd47"

console.log(window.location.hostname)
async function app() {
    // console.log(process.env.REACT_APP_API_ROOT || window.location.protocol + '//' + window.location.hostname + '/api')
    render(<Loading/>)
    // console.log(paypalClientId)

    // console.log((window as any).apiRoot)
    // console.log('var2', (window as any).var2)
    await apiClientService.configureClient()

    render(
        <PayPalScriptProvider options={{ components: 'buttons',clientId: paypalClientId, vault: true, currency: 'EUR'}}>
            <Suspense fallback={<Loading/>}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Suspense>
        </PayPalScriptProvider>
    )
}

function render(element: JSX.Element) {
    ReactDOM.render(
        <React.StrictMode>
            {element}
        </React.StrictMode>,
        document.getElementById('body')
    )
}
serviceWorkerRegistration.register();

app()