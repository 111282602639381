import sessionService from "../session/session.service"
import {Capacitor} from "@capacitor/core";

class CookieService {

    getAccepted(): boolean {
        if (Capacitor.isNativePlatform())
            return true
        if (sessionService.isAuthenticated())
            return true

        return localStorage.getItem('policy_accepted') !== null
    }

    setAccepted() {
        localStorage.setItem('policy_accepted', 'true')
    }

}

export default new CookieService()