import style from './index.module.scss'
import Overlay from "../../../theme/component/overlay/overlay.component";
import React, {useEffect, useState} from "react";
import {classNames} from "../../../utility/className.utility";
import {useTranslation} from "react-i18next";
import BlockDialog from "../BlockDialog";
import {ReportCampaignDialog} from "../ReportCampaignDialog";

type Props = {
    campaignId: string
    username: string
    openDialog: boolean
    closeDialog: () => void
    removeFromTimeline: (username: string) => void
}

export function CampaignMenu({ campaignId, username, openDialog, closeDialog, removeFromTimeline }: Props) {

    const { t, i18n } = useTranslation();

    const [dialogOpen, setDialogOpen] = useState(false)
    const [menuDisableBackdropClose, setDisableBackdropClose] = useState<boolean>(false)
    const [blockDialogOpen, setBlockDialogOpen] = useState(false)
    const [reportDialogOpen, setReportDialogOpen] = useState(false)

    useEffect(() => {
        setDialogOpen(openDialog)
        console.log('dialog', openDialog)
    }, [openDialog]);

    function closeMenu() {
        setDialogOpen(false);
        setBlockDialogOpen(false);
        setReportDialogOpen(false);
        closeDialog();
    }

    async function openBlockDialog() {
        setDialogOpen(false);
        setBlockDialogOpen(true);
    }

    async function openReportDialog() {
        setDialogOpen(false);
        setReportDialogOpen(true);
    }

    async function removeBlockedUser(username: string) {
        console.log('Remove ', username, ' from timeline')
    }

    // TODO localize Campaign Options
    return <>
        <Overlay title='Campaign Options' open={dialogOpen} onClose={() => setDialogOpen(false)} disableTapout={menuDisableBackdropClose}>
            <div className={classNames('mt3', style.options)}>
                <span className={classNames(style.pre_icon, style.icon_block)} onClick={openBlockDialog}>{t('campaign.contextmenu.block_user')}</span>
                <span className={classNames(style.pre_icon, style.icon_report)} onClick={openReportDialog}>{t('campaign.contextmenu.report_user')}</span>
            </div>
        </Overlay>
        <BlockDialog username={username} onBlock={() => removeFromTimeline(username)} openDialog={blockDialogOpen} setDialogOpen={closeMenu} />
        <ReportCampaignDialog id={campaignId} open={reportDialogOpen} onClose={closeMenu} />
    </>
}