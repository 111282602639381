import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import style from './index.module.scss';
import {
    InfluencerMonthlyPlan,
    InfluencerYearlyPlan, PremiumBenefitsSection
} from "../../theme/component/payment-plans";
import { DesktopPaymentPicker } from "../../theme/component/payment-picker/desktop";
import SessionService from "../../service/session/session.service";
import { useNavigate } from "react-router-dom";
import Panel from "../../theme/component/panel/panel.component";
import { BillingService } from "../../service/billing/billing.service";
import {Capacitor} from "@capacitor/core";
import {InAppPurchaseService} from "../../service/iap/InAppPurchaseService";
import {ProductEnum} from "../../service/iap/model/ProductEnum";

type Plan = {
    planId: string,
    name: string,
    type: ProductEnum
}

const isProduction = window.location.hostname === 'influspace.com'

const brandAccessMonthly = {
    planId: isProduction ? 'P-0NV86914HN9171738MY4OZXA' : 'P-63340961HC971183YMWYC55A',
    name: 'Monthly Payment Plan for Brand Access',
    type: ProductEnum.BRAND_MONTHLY
} as Plan

const brandAccessYearly = {
    planId: isProduction ? 'P-9NG5521757479102WMY4O2BQ' : 'P-1AY65338S2323582HMWYC5AQ',
    name: 'Yearly Payment Plan for Brand Access',
    type: ProductEnum.BRAND_YEARLY

} as Plan

const premiumAccessMonthly = {
    planId: isProduction ? 'P-50A61781RW747462VMY4OYQQ' : 'P-7YR91116UY077744PMWYC73I',
    name: 'Monthly Payment Plan for Premium Access',
    type: ProductEnum.PREMIUM_MONTHLY

} as Plan

const premiumAccessYearly = {
    planId: isProduction ? 'P-23R95149C0951882DMY4OX6Q' : 'P-8W568297R08956525MW2CQDI',
    name: 'Yearly Payment Plan for Premium Access',
    type: ProductEnum.PREMIUM_YEARLY

} as Plan

const plans = [brandAccessYearly, brandAccessMonthly, premiumAccessMonthly, premiumAccessYearly]

export default function Premium() {
    const { t } = useTranslation();
    const [planId, setPlanId] = useState<string>(undefined)
    const [planName, setPlanName] = useState<string>(undefined)
    const [active, setActive] = useState<boolean>(false)

    useEffect(() => {
        setPlanName(plans.find(plan => plan.planId === planId)?.name)
    }, [planId]);

    const navigate = useNavigate()

    useEffect(() => {
        const session = SessionService.getSessionDetails()
        if (session.premium) {
            navigate('/explore')
        }
        setActive(session.active)
    }, [navigate]);

    async function createSubscription(data: any, actions: any) {
        return actions.subscription.create({
            plan_id: planId,
        });
    }

    async function onApprove(data: any, actions: any) {
        BillingService.subscribe(data.subscriptionID, 'paypal').then(() => window.location.reload())
    }

    if (planId) {
        return <DesktopPaymentPicker name={planName} createSubscription={createSubscription} onApprove={onApprove} />
    }

    async function onBuy(planId: string) {
        if (Capacitor.isNativePlatform()) {
            const plan = plans.find(plan => plan.planId === planId)
            await InAppPurchaseService.purchaseSubscription(plan.type)
            window.location.reload()
        } else {
            setPlanId(planId)
        }
    }

    return (
        <section>
            <Panel className={style.panel}>
                {active ?
                    <h1 className={style.header}>{t('premium.header.buy_now')}</h1>
                    : <h1 className={style.header}>{t('premium.header.congratulations')}</h1>}
                {active ?
                    <h3 className={style.topText}>{t('premium.text.increase_reach')}</h3>
                    : <h3 className={style.topText}>{t('premium.text.upgrade_tier')}</h3>}
                <PremiumBenefitsSection></PremiumBenefitsSection>
                <div className={style.plansGrid}>
                    <InfluencerMonthlyPlan planId={premiumAccessMonthly.planId} onBuy={onBuy} />
                    <InfluencerYearlyPlan planId={premiumAccessYearly.planId} onBuy={onBuy} />
                </div>
            </Panel>
        </section>
    )
}
