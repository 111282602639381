import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../theme/hooks/use-query.component';
import TechnicalError from "../../components/app/component/error/technical/technical-error.component";
import Loading from "../../theme/layout/loading/loading.component";

type LoginProps = {
    onLogin: (loggedIn: boolean) => void;
};

export default function ThirdPartyLogin({ onLogin }: LoginProps) {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const provider = useQuery('provider');
    const platform = useQuery('platform');
    const joinToken = useQuery('joinToken');

    const navigate = useNavigate();
    const [error, setError] = useState<boolean>(false);


    useEffect(() => {
        redirect();
    }, [provider]);

    const redirectToDeepLink = (token: string | null, fallbackPath: string) => {
        const url = token !== 'undefined' ? `influspace://join?token=${token}` : `influspace://${fallbackPath}`;
        window.location.href = url;
    };

    const redirectToWeb = (token: string | null, path: string) => {
        if (token !== 'undefined') {
            navigate(`${path}?token=${token}`);
        } else {
            window.location.href = '/';
        }
    };

    const redirect = async () => {
        try {
            if (platform === 'ios') {
                redirectToDeepLink(joinToken, 'login');
            } else {
                redirectToWeb(joinToken, '/join');
            }
        } catch (err) {
            console.error("Error during third-party authorization:", err);
            setError(true);
        }
    };

    return error ? <TechnicalError /> : <Loading />;
}
