import React, {useEffect, useState} from 'react';
import style from './index.module.scss';
import Support from './component/support';
import FooterSection from './component/footer';
import NewsletterSection from './component/newsletter';
import JoinSection from './component/join';
import  {Navigation} from './component/nvigation'
import LanguageSelector from "../../components/common/language-selection/index.component";
import {Testimonials} from "./component/testimonials";
import {KnownFrom} from "./component/known-from";
import {Benefits2} from "./component/benefits2";
import {HeroSection2} from "./component/hero2";
import {IntroductionVideo} from "./component/introduction-video";
import BlackFridaySubscription from "./component/black-friday-subscription";
import Subscriptions from "./component/subscription";
import {Capacitor} from "@capacitor/core";
import {useNavigate} from "react-router-dom";
import Loading from "../../theme/layout/loading/loading.component";

export default function Landing() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(Capacitor.isNativePlatform())

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            navigate('/login')
            setLoading(false)
        }
    }, []);


    if (loading) {
        return <Loading/>
    }

    return <div className={style.landing}>
        <LanguageSelector />
        <Navigation />
        <HeroSection2 />
        {/*<OverviewSection />*/}
        {/*<ShowBusinessProvider>*/}
        {/*</ShowBusinessProvider >*/}
        <IntroductionVideo />
        <Benefits2 />
        <section className={style.container}>
            {/*<BlackFridaySubscription />*/}
            <Subscriptions />
            <JoinSection />
        </section>
            <KnownFrom/>
        <section className={style.container}>
            <Testimonials/>
            <Support />
            {/*<BlogSection />*/}
            <NewsletterSection />
            <FooterSection />
        </section>
    </div>

}