import React, { useState } from "react";
import { IOffer } from "../../../../../../service/marketplace/model/offer.model";
import style from '../../index.module.scss'
import { useNavigate } from "react-router-dom";
import marketplaceService from "../../../../../../service/marketplace/marketplace.service";
import { OfferHeader } from "../header";
import { Bookmark } from "../../../../../../theme/component/bookmark";

type OfferTileProps = {
    offer: IOffer
}

export function OfferOverview({ offer }: OfferTileProps) {
    const navigate = useNavigate()

    const [bookmarked, setBookmarked] = useState<boolean>(offer?.bookmarked);

    if (offer === undefined) {
        return <></>;
    }

    function Description({ description }: { description: string }) {
        return <div className={style.description_short}>
            {description}
        </div>
    }

    function OfferContextMenu() {
        if (offer.owned) {
            return <></>
        }

        return <div className={style.context_menu}>
            <span onClick={bookmarkOffer} className={style.bookmark}><Bookmark enabled={bookmarked} /></span>
        </div>
    }

    async function bookmarkOffer() {
        try {
            await marketplaceService.bookmarkOffer(offer)
            setBookmarked(!bookmarked)
        } catch (error) {
            //TODO: Meldung wenn like failed
        }
    }


    function onClickHandler(event: any) {
        if (event.target.id !== 'bookmark' && event.target.id !== 'apply' && event.target.id !== 'menu') {
            navigate(offer.premiumOnlyUntil ? '/premium' : `/marketplace/offer/${offer.ref}`)
        }
    }

    return (
        <span className={style.panel} onClick={(event => onClickHandler(event))}>
            <OfferHeader offer={offer} />
            <OfferContextMenu />
        </span>
    );
}



