import React from 'react'

import style from './menu-item.module.scss'
import {classNames} from "../../../../../../utility/className.utility";
import {DetailView} from "../../../../model/detail-view.model";

type MenuItemProps = {
    name: string,
    detailView: DetailView,
    selected: DetailView,
    onSelection: (details: DetailView) => void
}

export default function MenuItem({ detailView, onSelection, selected, name}: MenuItemProps) {
    const selectedStyle = selected === detailView ? style.selected : ''

    return <li className={style.item} onClick={() => onSelection(detailView)}>
        <span className={classNames(
            style.content,
            style.icon,
            style[`icon_${detailView.toLowerCase()}`],
            'pre-icon',
            'post-icon',
            selectedStyle)}>{name}</span>
    </li>
}