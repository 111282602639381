import axios from 'axios'
import { IKeyword } from '../../influspace/profile/component/profile-audience/component/profile-keywords/model/profile-keyword.model'
import { IAudience } from '../../influspace/profile/component/profile-audience/model/demography.model'
import ICollaboration from '../../influspace/profile/component/profile-collaboration/model/collaboration.model'
import ProfileLink from '../../influspace/profile/component/profile-links/model/profile-link.model'
import ProfileView from './model/profile-view.model'
import {OfferReference} from "../chat/model/OfferReference";
import {IProfileTile} from "../search/model/profile-tile.model";
import {IOffer} from "../marketplace/model/offer.model";

class ProfileService {

    private root = '/profile'
    private client = axios

    public async getProfile(username: string, signal?: AbortSignal): Promise<ProfileView> {
        const response = await this.client.get(`${this.root}/view/${username}`, { signal })

        return response?.data
    }

    public async setDisplayname(displayname: string): Promise<string> {
        const response = await this.client.post(`${this.root}/displayname`, { displayname })

        return response?.data?.displayname
    }

    public async setDescription(description: string): Promise<string> {
        const response = await this.client.post(`${this.root}/description`, { description: description })

        return response?.data?.description
    }

    public async setLanguage(language: string): Promise<string> {
        const response = await this.client.post(`${this.root}/language`, { language })

        return response?.data?.description
    }

    public async setLocation(location: ILocation): Promise<ILocation> {
        const response = await this.client.post(`${this.root}/location`, location)

        return response?.data
    }

    public async getLocationHints(locationString: string): Promise<ILocation[]> {
        const response = await this.client.get(`${this.root}/location/hints?locationString=${locationString}`)

        return response?.data
    }

    public async addLink(link: ProfileLink): Promise<ProfileLink> {
        const response = await this.client.post(`${this.root}/link`, link)

        return response?.data
    }

    public async updateLink(link: ProfileLink): Promise<ProfileLink> {
        const response = await this.client.patch(`${this.root}/link`, link)

        return response?.data
    }

    public async deleteLink(link: ProfileLink): Promise<ProfileLink> {
        const response = await this.client.delete(`${this.root}/link`, { 
            params: {
                ref: link?.ref
            }
        })

        return response?.data
    }

    public async saveCollaborations(collaborations: ICollaboration): Promise<ICollaboration> {
        const response = await this.client.post(`${this.root}/collaboration`, collaborations)
    
        return response?.data
    }

    public async getCollaborations(collaborations: ICollaboration): Promise<ICollaboration> {
        const response = await this.client.get(`${this.root}/collaboration`)
        return response?.data
    }

    public async setDemography(demography: IAudience): Promise<IAudience> {
        const response = await this.client.post(`${this.root}/audience`, demography)
    
        return response?.data
    }

    public async setAudience(targetAge: string, targetGender: string): Promise<IAudience> {
        const response = await this.client.post(`${this.root}/audience`, { targetAge, targetGender })

        return response?.data
    }

    public async addKeyword(keyword: IKeyword): Promise<IKeyword> {
        const response = await this.client.post(`${this.root}/keyword`, keyword)
    
        return response?.data
    }

    public async editKeyword(keyword: IKeyword): Promise<IKeyword> {
        const response = await this.client.patch(`${this.root}/keyword`, keyword)
    
        return response?.data
    }

    public async deleteKeyword(keyword: IKeyword): Promise<IKeyword> {
        const response = await this.client.delete(`${this.root}/keyword?ref=${keyword.ref}`)
    
        return response?.data
    }

    public async getKeywordHints(hint: string): Promise<string[]> {
        try {
            const response = await this.client.get(`${this.root}/keyword/hint?query=${hint}`)
            return response.data
        } catch (error) {
            return []
        }    
    }

    public async getOffers(signal?: AbortSignal): Promise<OfferReference[]> {
        const response = await this.client.get(`${this.root}/offers`, { signal })

        return response?.data
    }

    public async bookmarkProfile(username: string) {
        await this.client.post(`${this.root}/bookmark/${username}`)
    }

    public async blockProfile(username: string) {
        await this.client.post(`${this.root}/block/${username}`)
    }

    async deleteLocation() {
        await this.client.delete(`${this.root}/location`)
    }

    async getBookmarks(): Promise<[IProfileTile[], IOffer[]]> {
        const response = await this.client.get(`${this.root}/bookmark`)
        return [response?.data.bookmarkedProfiles, response?.data.bookmarkedOffers]
    }

    async getKeywordSuggestions(): Promise<string[]> {
        const response = await this.client.get(`${this.root}/keyword/suggestions`)
        return response?.data
    }

    async boostProfile(): Promise<Date> {
        const response = await this.client.post(`${this.root}/boost`)
        return response.data.boostedUntil
    }

    public async reportProfile(username: string, topic: string, issue: string, reason: string) {
        await this.client.post(`${this.root}/report`, {username, topic, issue, reason})
    }

}

export default new ProfileService()